import { useScriptTag } from '#imports'

import { paymentAPI } from '@backmarket/http-api'

export function useKlarnaLibrary(
  klarnaPaymentMethod: paymentAPI.PaymentMethod,
  onLoaded?: Parameters<typeof useScriptTag>[1],
  options?: Parameters<typeof useScriptTag>[2],
) {
  const { libraryUrl, clientId, environment } =
    klarnaPaymentMethod.config as paymentAPI.KlarnaPaymentMethodConfig

  if (!libraryUrl || !clientId) {
    throw new Error(
      'Missing libraryUrl and/or clientId in PayPal payment method config',
    )
  }

  return useScriptTag(libraryUrl, onLoaded, {
    ...options,
    attrs: {
      'data-environment': environment,
      'data-client-id': clientId,
      ...options?.attrs,
    },
  })
}
