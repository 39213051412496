import { useScriptTag } from '#imports'
import { type MaybeRefOrGetter, computed, toValue } from 'vue'

import type { paymentAPI } from '@backmarket/http-api'

import {
  PAYPAL_CLIENT_ID_PLACEHOLDER,
  PAYPAL_LIBRARY_URL,
} from './PaypalModal.config'

function usePaypalLibraryUrl(
  paypalPaymentMethod: MaybeRefOrGetter<paymentAPI.PaymentMethod>,
) {
  return computed(() => {
    const { paypalClientId } = toValue(paypalPaymentMethod)
      .config as paymentAPI.PaypalPaymentMethodConfig

    if (!paypalClientId) {
      throw new Error('Missing paypalClientId in PayPal payment method config')
    }

    return PAYPAL_LIBRARY_URL.replace(
      PAYPAL_CLIENT_ID_PLACEHOLDER,
      paypalClientId,
    )
  })
}

export function usePaypalLibrary(
  paypalPaymentMethod: MaybeRefOrGetter<paymentAPI.PaymentMethod>,
  onLoaded?: Parameters<typeof useScriptTag>[1],
  options?: Parameters<typeof useScriptTag>[2],
) {
  return useScriptTag(
    usePaypalLibraryUrl(paypalPaymentMethod),
    onLoaded,
    options,
  )
}
