import { type paymentAPI } from '@backmarket/http-api'

import { type PaymentAdvertisingConfigFilter } from '../types/PaymentAdvertisingConfig'

/**
 * @returns A {@link PaymentAdvertisingConfigFilter payment advertising filter} that
 * will return `isAvailable: true` if the payment methods list contains one
 * with the given {@link bmCode}.
 */
export function availableWith(
  bmCode: paymentAPI.PaymentMethodCode,
): PaymentAdvertisingConfigFilter {
  return (paymentMethods: paymentAPI.PaymentMethod[]) => {
    const forMethods = paymentMethods.filter(
      (paymentMethod) => paymentMethod.bmCode === bmCode,
    )

    return {
      isAvailable: forMethods.length > 0,
      forMethods,
    }
  }
}
