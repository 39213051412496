<template>
  <component :is="tag" v-if="advertisement.isAdvertised">
    <slot name="prefix" />

    <slot name="default" v-bind="advertisement">
      <PaymentMethodsErrors
        :class="[classes.typography, classes.color]"
        :inner-class="`flex items-center ${classes.gap}`"
        :payment-methods="[advertisement.method]"
      >
        <PaymentIcons
          :class="['self-start', classes.saturate, iconClasses]"
          :methods="[advertisement.method]"
          show-disabled-methods
        />

        <div>
          {{ advertisementDetails.plainText }}

          <component
            :is="advertisement.config.modal"
            v-if="hasModal"
            :base-price="basePrice"
            class="inline"
            :payment-methods="paymentMethods"
            :spot="advertisement.spot"
          >
            <template #trigger="{ open }">
              <RevLink @click="openAndEmitEvent(open)">
                {{ advertisementDetails.linkText }}
              </RevLink>
            </template>
          </component>
        </div>
      </PaymentMethodsErrors>
    </slot>
    <slot name="suffix" />
  </component>

  <component :is="props.tag" v-else-if="$slots.fallback">
    <slot name="fallback" />
  </component>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { type Price, paymentAPI } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { tw } from '@backmarket/utils/string/tw'
import { RevLink } from '@ds/components/Link'

import {
  PaymentIcons,
  PaymentMethodsErrors,
  isPaymentMethodEnabled,
  usePaymentMethodsWithFallback,
} from '../../../methods'
import { usePaymentAdvertisement } from '../../composables/usePaymentAdvertisement'
import {
  type CartPaymentAdvertisingConfig,
  type LandingPaymentAdvertisingConfig,
  type OrdersPaymentAdvertisingConfig,
  type ProductPaymentAdvertisingConfig,
  type SearchPaymentAdvertisingConfig,
} from '../../types/PaymentAdvertisingConfig'
import { PaymentAdvertisingSpot } from '../../types/PaymentAdvertisingSpot'

import translations from './PaymentAdvertisement.translations'

const i18n = useI18n()

const emit = defineEmits(['open'])

const props = withDefaults(
  defineProps<{
    basePrice: Price
    iconClasses?: string
    spot: PaymentAdvertisingSpot
    paymentMethods?: paymentAPI.PaymentMethod[] | null
    tag?: string
    textSize?: '1' | '2'
  }>(),
  {
    iconClasses: '',
    paymentMethods: null,
    tag: 'div',
    textSize: '2',
  },
)

const paymentMethods = usePaymentMethodsWithFallback(() => props.paymentMethods)

const includeDisabledMethods = computed(
  () => props.spot === PaymentAdvertisingSpot.CART,
)
const advertisement = usePaymentAdvertisement(
  () => props.spot,
  paymentMethods,
  { includeDisabledMethods },
)

const isAvailable = computed(
  () =>
    advertisement.value.isAdvertised &&
    isPaymentMethodEnabled(advertisement.value.method),
)

const hasModal = computed(
  () =>
    advertisement.value.isAdvertised &&
    advertisement.value.config.modal &&
    isAvailable.value,
)

const advertisementDetails = computed(() => {
  if (!advertisement.value.isAdvertised) {
    return {}
  }

  if (props.spot === PaymentAdvertisingSpot.CART) {
    const config = advertisement.value.config as CartPaymentAdvertisingConfig

    return isAvailable.value
      ? { linkText: i18n(config.available) }
      : { plainText: i18n(config.unavailable) }
  }

  if (props.spot === PaymentAdvertisingSpot.SEARCH) {
    const config = advertisement.value.config as SearchPaymentAdvertisingConfig

    return {
      linkText: i18n(translations.learnMore),
      plainText: i18n(config.heading),
    }
  }

  const config = advertisement.value.config as
    | LandingPaymentAdvertisingConfig
    | OrdersPaymentAdvertisingConfig
    | ProductPaymentAdvertisingConfig

  return {
    linkText: i18n(translations.learnMore),
    plainText: i18n(config.text),
  }
})

const classesByTextSize = {
  1: {
    typography: tw`body-1`,
    gap: tw`gap-x-8`,
  },
  2: {
    typography: tw`body-2`,
    gap: tw`gap-x-4`,
  },
}

const classesByAvailability = {
  true: {
    color: tw`text-primary`,
    saturate: '',
  },
  false: {
    color: tw`tw-text-primary-disabled`,
    saturate: tw`saturate-0`,
  },
}

const classes = computed(() => ({
  ...classesByAvailability[`${isAvailable.value}`],
  ...classesByTextSize[props.textSize],
}))

function openAndEmitEvent(open: () => void) {
  open()
  emit('open', advertisement)
}
</script>
