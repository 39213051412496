import { MarketCountryCode, paymentAPI } from '@backmarket/http-api'

import { paymentGroupIcon } from '../../methods'
import AffirmModal from '../components/AffirmModal'
import KlarnaModal from '../components/KlarnaModal'
import KlarnaPaymentSummaryModal from '../components/KlarnaPaymentSummaryModal'
import KlarnaSizeableIcon from '../components/KlarnaSizeableIcon'
import OneyModal from '../components/OneyModal'
import PaypalModal from '../components/PaypalModal'
import { isPaypalModalAvailable } from '../components/PaypalModal/isPaypalModalAvailable'
import { type PaymentAdvertisingConfig } from '../types/PaymentAdvertisingConfig'
import { type PaymentAdvertisingId } from '../types/PaymentAdvertisingId'
import { availableWhenAllOf } from '../utils/availableWhenAllOf'
import { availableWhenAnyOf } from '../utils/availableWhenAnyOf'
import { availableWith } from '../utils/availableWith'

import translations from './advertising.translations'

const { PaymentMethodCode, PaymentGroup } = paymentAPI

export const PAYMENT_METHODS_ADVERTISING: Record<
  PaymentAdvertisingId,
  PaymentAdvertisingConfig
> = {
  oneyGeneric: {
    isAvailableFor: availableWhenAnyOf(
      PaymentMethodCode.ONEY3X,
      PaymentMethodCode.ONEY4X,
      PaymentMethodCode.ONEY6X,
      PaymentMethodCode.ONEY10X,
      PaymentMethodCode.ONEY12X,
    ),
    cart: {
      available: translations.installmentPaymentAvailable,
      unavailable: translations.installmentPaymentUnavailable,
      modal: OneyModal,
    },
    landing: {
      text: translations.payInInstallments,
      icon: null,
      modal: OneyModal,
    },
    orders: {
      text: translations.paidWithOney,
      modal: null,
    },
    product: {
      text: translations.payInInstallments,
      modal: OneyModal,
    },
  },
  affirm: {
    isAvailableFor: availableWith(PaymentMethodCode.AFFIRM),
    cart: {
      available: translations.installmentPaymentAvailable,
      unavailable: translations.installmentPaymentUnavailable,
      modal: AffirmModal,
    },
    landing: {
      text: translations.payInInstallments,
      icon: null,
      modal: AffirmModal,
    },
    product: {
      text: translations.payInInstallments,
      modal: AffirmModal,
    },
  },
  klarnaAll: {
    isAvailableFor: availableWhenAllOf(
      PaymentMethodCode.KLARNA_PAY_LATER,
      PaymentMethodCode.KLARNA_SLICE_IT,
    ),
    landing: {
      text: translations.payLaterOrInInstallments,
      icon: paymentGroupIcon(PaymentGroup.KLARNA_PAY_LATER),
      modal: KlarnaModal,
    },
    search: {
      heading: translations.klarnaSimplyPay,
      body: translations.klarnaPaySafely,
      smallIcon: paymentGroupIcon(PaymentGroup.KLARNA_PAY_LATER),
      largeIcon: {
        is: KlarnaSizeableIcon,
        props: { paymentGroup: PaymentGroup.KLARNA_PAY_LATER },
      },
      modal: KlarnaModal,
    },
    cart: {
      available: translations.installmentPaymentAvailable,
      unavailable: translations.installmentPaymentUnavailable,
      modal: KlarnaModal,
    },
    product: {
      text: translations.payLaterOrInInstallments,
      modal: KlarnaModal,
    },
  },
  klarnaPayLater: {
    isAvailableFor: availableWith(PaymentMethodCode.KLARNA_PAY_LATER),
    landing: {
      text: translations.payLater,
      icon: paymentGroupIcon(PaymentGroup.KLARNA_PAY_LATER),
      modal: KlarnaModal,
    },
    orders: {
      text: translations.paidWithKlarnaPayLater,
      modal: KlarnaPaymentSummaryModal,
    },
    search: {
      heading: translations.klarnaSimplyPay,
      body: translations.klarnaPaySafely,
      smallIcon: paymentGroupIcon(PaymentGroup.KLARNA_PAY_LATER),
      largeIcon: {
        is: KlarnaSizeableIcon,
        props: { paymentGroup: PaymentGroup.KLARNA_PAY_LATER },
      },
      modal: KlarnaModal,
    },
    cart: {
      available: translations.payLaterAvailable,
      unavailable: translations.payLaterUnavailable,
      modal: KlarnaModal,
    },
    product: {
      text: translations.payLater,
      modal: KlarnaModal,
    },
  },
  klarnaSliceIt: {
    isAvailableFor: availableWith(PaymentMethodCode.KLARNA_SLICE_IT),
    cart: {
      available: translations.installmentPaymentAvailable,
      unavailable: translations.installmentPaymentUnavailable,
      modal: KlarnaModal,
    },
    landing: {
      text: translations.payInInstallments,
      icon: paymentGroupIcon(PaymentGroup.KLARNA_PAY_LATER),
      modal: KlarnaModal,
    },
    orders: {
      text: translations.paidWithKlarnaSliceIt,
      modal: KlarnaPaymentSummaryModal,
    },
    product: {
      text: translations.payInInstallments,
      modal: KlarnaModal,
    },
  },
  paypal: {
    isAvailableFor: isPaypalModalAvailable,
    cart: {
      available: translations.paypalPaymentAvailable,
      unavailable: translations.paypalPaymentUnavailable,
      modal: PaypalModal,
    },
    product: {
      text: translations.paypalPaymentAvailable,
      modal: PaypalModal,
    },
  },
}

export const AdvertisingDefaultOrder = Object.keys(
  PAYMENT_METHODS_ADVERTISING,
) as PaymentAdvertisingId[]

const AdvertisingPaypalFirst: PaymentAdvertisingId[] = [
  'paypal',
  ...AdvertisingDefaultOrder,
]

export const PAYMENT_METHODS_ADVERTISING_BY_COUNTRY: Partial<
  Record<MarketCountryCode, PaymentAdvertisingId[]>
> = {
  ES: AdvertisingPaypalFirst,
  FR: AdvertisingPaypalFirst,
  IT: AdvertisingPaypalFirst,
}
