import { type paymentAPI } from '@backmarket/http-api'

import { type PaymentAdvertisingConfigFilter } from '../types/PaymentAdvertisingConfig'

/**
 * @returns A {@link PaymentAdvertisingConfigFilter payment advertising filter} that
 * will return `isAvailable: true` if the payment methods list contains all
 * of the given {@link bmCodes}.
 */
export function availableWhenAllOf(
  ...bmCodes: paymentAPI.PaymentMethodCode[]
): PaymentAdvertisingConfigFilter {
  return (paymentMethods: paymentAPI.PaymentMethod[]) => {
    const forMethods = paymentMethods.filter((paymentMethod) =>
      bmCodes.includes(paymentMethod.bmCode),
    )

    const isAvailable =
      new Set(forMethods.map(({ bmCode }) => bmCode)).size ===
      new Set(bmCodes).size

    return {
      isAvailable,
      forMethods,
    }
  }
}
