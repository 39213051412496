import { type Market, paymentAPI } from '@backmarket/http-api'

import { isPaymentMethodEnabled } from '../../methods'
import { type PaymentAdvertisementOptions } from '../types/PaymentAdvertisementOptions'
import { type PaymentAdvertisementConfigBySpot } from '../types/PaymentAdvertisingConfig'
import { PaymentAdvertisingId } from '../types/PaymentAdvertisingId'
import { PaymentAdvertisingSpot } from '../types/PaymentAdvertisingSpot'

import { sortPaymentMethodsAdvertisement } from './sortPaymentMethodsAdvertisement'

export function findAvailablePaymentAdvertisement<
  Spot extends PaymentAdvertisingSpot,
  Config = PaymentAdvertisementConfigBySpot[Spot],
>(
  spot: Spot,
  paymentMethods: paymentAPI.PaymentMethod[],
  options: PaymentAdvertisementOptions & { market: Market },
) {
  const methods = options?.includeDisabledMethods
    ? paymentMethods
    : paymentMethods.filter(isPaymentMethodEnabled)

  for (const [id, config] of sortPaymentMethodsAdvertisement(options.market)) {
    if (config[spot]) {
      const { isAvailable, forMethods } = config.isAvailableFor(methods)
      if (isAvailable) {
        return {
          id: id as PaymentAdvertisingId,
          methods: forMethods,
          config: config[spot] as Config,
        }
      }
    }
  }

  return null
}
