import { MarketCountryCode } from '@backmarket/http-api'

export const PAYPAL_LIBRARY_URL =
  'https://www.paypal.com/sdk/js?components=messages&client-id={PAYPAL_CLIENT_ID}'

export const PAYPAL_CLIENT_ID_PLACEHOLDER = '{PAYPAL_CLIENT_ID}'

// https://developer.paypal.com/limited-release/sdk-pay-later-messaging-cross-border/#eligibility
export const PAYPAL_PAY_LATER_AVAILABLE_COUNTRIES = [
  MarketCountryCode.AU,
  MarketCountryCode.DE,
  MarketCountryCode.ES,
  MarketCountryCode.FR,
  MarketCountryCode.GB,
  MarketCountryCode.IT,
  MarketCountryCode.US,
]
