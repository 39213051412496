<template>
  <!--
    Klarna uses web components to display on-site messaging, so it is declared
    simply with a custom <klarna-placement> tag.
    Read more: https://docs.klarna.com/on-site-messaging/

    /!\ It MUST be registered in `nuxt.config.ts` as a custom element, otherwise
    Vue will try to resolve it. The same must be done in `vitest.config.ts` file
    as well.

    export default defineNuxtConfig({
      vue: {
        compilerOptions: {
          isCustomElement: (tag) => ['klarna-placement'].includes(tag),
        },
      }
    })

    Read more: https://vuejs.org/guide/extras/web-components
  -->
  <div ref="klarnaPlaceholder">
    <!-- eslint-disable-next-line vue/component-name-in-template-casing -->
    <klarna-placement
      v-bind="attrs"
      ref="klarnaPlacement"
      class="hidden"
      :data-key="placementKey"
      :data-locale="locale"
      :data-purchase-amount="purchaseAmount"
    >
    </klarna-placement>
  </div>
</template>

<script setup lang="ts">
import { type Ref, computed, onUnmounted, ref, useAttrs } from 'vue'

import type { Price, paymentAPI } from '@backmarket/http-api'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { useMutationObserver } from '@backmarket/utils/composables/useMutationObserver'
import { noop } from '@backmarket/utils/function/noop'
import { until } from '@backmarket/utils/function/until'
import { priceToMinorUnits } from '@backmarket/utils/math/priceToMinorUnits'
import { toRfc4646Locale } from '@backmarket/utils/string/toRfc4646Locale'

import { KLARNA_LIBRARY_RENDERING_TIMEOUT } from './KlarnaModal.config'
import { useKlarnaLibrary } from './useKlarnaLibrary'

const props = defineProps<{
  paymentMethod: paymentAPI.PaymentMethod
  placementKey: string
  basePrice: Price
}>()

defineOptions({
  inheritAttrs: false,
})

const attrs = useAttrs()

const { market } = useMarketplace()
const locale = computed(() => toRfc4646Locale(market.defaultLocale))

const purchaseAmount = computed(() => priceToMinorUnits(props.basePrice))

const klarnaPlaceholder: Ref<HTMLElement | null> = ref(null)
const klarnaPlacement: Ref<HTMLElement | null> = ref(null)

const klarnaPlacementButton: Ref<HTMLButtonElement | null> = ref(null)
const updateKlarnaPlacementButton = () => {
  const button =
    klarnaPlaceholder.value
      ?.querySelector('*')
      ?.shadowRoot?.querySelector('button') ?? null

  // The Klarna lib produces an empty button when it's not ready
  // We need to wait for the button to have a text content
  // before we can click it
  if (button?.innerText?.trim()) {
    klarnaPlacementButton.value = button
  }
}

// Since Klarna library does not provide any hook or callback we can use to
// know when the "placement" element is loaded, we must observe mutations on
// the element, then update klarnaPlacementButton.
useMutationObserver(
  klarnaPlaceholder,
  () => {
    updateKlarnaPlacementButton()
  },
  { childList: true },
)

// Sadly, the MutationObserver doesn't work in all cases
// This interval is a fallback to make sure we don't miss the button
let interval: ReturnType<typeof setInterval> | null = null
const startPolling = () => {
  interval = setInterval(() => {
    updateKlarnaPlacementButton()
  }, 500)
}
const stopPolling = () => {
  if (interval) {
    clearInterval(interval)
  }
}

onUnmounted(() => {
  stopPolling()
})

const { load: loadLibrary } = useKlarnaLibrary(
  props.paymentMethod,
  noop,
  // Lazy-load the library, to improve performance (especially important on the product page).
  { immediate: false },
)

defineExpose({
  /**
   * Load the Klarna library (if not done yet), then wait for the Klarna
   * placement button to be ready.
   *
   * @param timeout Delay in milliseconds to wait for the button to be displayed. 0 for never timed out
   *
   * @returns A Promise that resolves to the placement button.
   * @throws If the library fails to load
   * @throws If the button is not available after `timeout`
   */
  async load(timeout = KLARNA_LIBRARY_RENDERING_TIMEOUT) {
    await loadLibrary()

    try {
      startPolling()
      await until(klarnaPlacementButton).toBeTruthy({
        timeout,
        throwOnTimeout: true,
      })
    } finally {
      stopPolling()
    }

    return klarnaPlacementButton.value as HTMLButtonElement
  },

  debug() {
    return klarnaPlacement.value?.outerHTML
  },
})
</script>
