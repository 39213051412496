<template>
  <div class="rounded-md bg-[#ffb3c7]">
    <RevIllustration
      alt=""
      class="mx-auto block h-full w-full"
      :height="40"
      :src="icon"
      :width="40"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { paymentAPI } from '@backmarket/http-api'
import { RevIllustration } from '@ds/components/Illustration'

import { type PaymentIconPath, paymentGroupIcon } from '../../../methods'

const props = defineProps<{ paymentGroup: paymentAPI.PaymentGroup }>()

const icon = computed(
  // We can safely assume that paymentGroupIcon() returns a valid path here,
  // because `paymentGroup` should only be a Klarna payment method's group.
  () => paymentGroupIcon(props.paymentGroup) as PaymentIconPath,
)
</script>
