<template>
  <div data-test="klarna-modal">
    <KlarnaPlacementWrapper
      ref="placement"
      :base-price="props.basePrice"
      :locale="market.defaultLocale"
      :payment-method="klarnaPaymentMethod"
      :placement-key="settings.placementKey"
    />
    <slot v-bind="{ open }" name="trigger" />
  </div>
</template>

<script setup lang="ts">
import { type Ref, computed, ref } from 'vue'

import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'

import { usePaymentMethodsWithFallback } from '../../../methods'
import type { PaymentAdvertisingModalProps } from '../../types/PaymentAdvertisingModalProps'

import {
  KLARNA_MODAL_SETTINGS,
  KLARNA_PAYMENT_METHODS,
} from './KlarnaModal.config'
import KlarnaPlacementWrapper from './KlarnaPlacementWrapper.vue'

const props = withDefaults(defineProps<PaymentAdvertisingModalProps>(), {
  name: 'KlarnaModal',
  paymentMethods: null,
})

const market = computed(() => useMarketplace().market)
const settings = computed(() => KLARNA_MODAL_SETTINGS[props.spot])

const paymentMethods = usePaymentMethodsWithFallback(() => props.paymentMethods)
const klarnaPaymentMethod = computed(() => {
  const paymentMethod = paymentMethods.value.find(({ bmCode }) =>
    KLARNA_PAYMENT_METHODS.includes(bmCode),
  )

  if (!paymentMethod) {
    throw new Error('Could not find any Klarna payment method')
  }

  return paymentMethod
})

type KlarnaPlacementWrapperType = InstanceType<typeof KlarnaPlacementWrapper>
const placement: Ref<KlarnaPlacementWrapperType | null> = ref(null)

async function open() {
  try {
    if (!placement.value) {
      throw new Error('Unexpected error: Klarna placement is not available')
    }

    const button = await placement.value.load()
    button.click()
  } catch (error) {
    const logger = useLogger()
    logger.error('[PAYMENT-ADVERTISING] Unable to display Klarna modal', {
      error: error as Error,
      type: 'PAYMENT_ADVERTISING_ERROR',
      // Additional information to help understand the state of the DOM when an
      // error occurs. See PAYIN-2571 for more details.
      klarnaDebug: {
        html: placement.value?.debug(),
        isKlarnaSdkLoaded: Boolean(window.Klarna),
        paymentMethod: klarnaPaymentMethod.value,
      },
    })
  }
}
</script>
