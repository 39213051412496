import { paymentAPI } from '@backmarket/http-api'

import { PaymentAdvertisingSpot } from '../../types/PaymentAdvertisingSpot'

import { KlarnaPlacementKey } from './KlarnaModal.constants'

export const KLARNA_PAYMENT_METHODS: paymentAPI.PaymentMethodCode[] = [
  'klarna_slice_it',
  'klarna_pay_later',
]

export const KLARNA_MODAL_SETTINGS = {
  [PaymentAdvertisingSpot.PRODUCT]: {
    placementKey: KlarnaPlacementKey.PRODUCT,
  },
  [PaymentAdvertisingSpot.CART]: {
    placementKey: KlarnaPlacementKey.PRODUCT,
  },
  [PaymentAdvertisingSpot.LANDING]: {
    placementKey: KlarnaPlacementKey.SITEWIDE,
  },
  [PaymentAdvertisingSpot.ORDERS]: {
    placementKey: KlarnaPlacementKey.SITEWIDE,
  },
  [PaymentAdvertisingSpot.SEARCH]: {
    placementKey: KlarnaPlacementKey.SITEWIDE,
  },
}

export const KLARNA_LIBRARY_RENDERING_TIMEOUT = 30000
