<template>
  <div data-test="paypal-modal">
    <slot v-bind="{ open }" name="trigger" />
  </div>
</template>

<script setup lang="ts">
import { type Ref, computed, ref } from 'vue'

import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { noop } from '@backmarket/utils/function/noop'

import type { PaypalMessagesModal } from '../../../../../types/paypal'
import { usePaymentMethodsWithFallback } from '../../../methods'
import { type PaymentAdvertisingModalProps } from '../../types/PaymentAdvertisingModalProps'

import { PAYPAL_PAY_LATER_AVAILABLE_COUNTRIES } from './PaypalModal.config'
import { usePaypalLibrary } from './usePaypalLibrary'

const props = withDefaults(defineProps<PaymentAdvertisingModalProps>(), {
  name: 'PaypalModal',
  paymentMethods: null,
})

const paymentMethods = usePaymentMethodsWithFallback(() => props.paymentMethods)

const paypalPaymentMethod = computed(() => {
  const paypal = paymentMethods.value.find(({ bmCode }) => bmCode === 'paypal')

  if (!paypal) {
    throw new Error('Could not find PayPal payment method')
  }

  return paypal
})

const paypalOptions = computed(() => {
  const { market } = useMarketplace()

  const buyerCountry =
    PAYPAL_PAY_LATER_AVAILABLE_COUNTRIES.find(
      (countryCode) => countryCode === market.countryCode,
    ) || 'US'

  return {
    amount: parseFloat(props.basePrice.amount),
    currency: props.basePrice.currency,
    buyerCountry,
  }
})

const { load } = usePaypalLibrary(
  paypalPaymentMethod,
  noop,
  // Lazy-load the library, to improve performance (especially important on the product page).
  { immediate: false },
)

const modal: Ref<PaypalMessagesModal | null> = ref(null)

async function open() {
  await load()

  if (!window.paypal) {
    throw new Error('Failed to initialize PayPal library')
  }

  if (!modal.value) {
    modal.value = window.paypal.MessagesModal(paypalOptions.value) ?? null
  }

  // Update props systematically because MessagesModal args only work the first time the library is loaded
  modal.value.updateProps(paypalOptions.value)

  modal.value.show()
}
</script>
